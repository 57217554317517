import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from 'mdbreact'

import React from 'react'

const Footer = (props) => {
  let facebookURLsuffix = 'Myskillednursingcare'
  if (typeof props.fb_id === 'undefined') {
  } else {
    if (props.fb_id === '' || props.fb_id === null) {
    } else {
      facebookURLsuffix = props.fb_id
    }
  }

  let nj_centers = [
    '/arborglen',
    '/cranbury',
    '/hollymanor',
    '/jerseyshore',
    '/lopatcong',
    '/mapleglen',
    '/millville',
    '/northcape',
    '/ridgewoodnj',
    '/southernocean',
    '/troyhills',
  ]

  let shownj = !nj_centers.includes(props.loc)

  return (
    <MDBFooter className="font-small pt-0 lightgray-bk-Genesis">
      <MDBContainer>
        <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center">
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="/about-us" className="black-link-Genesis">
                About Us
              </a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="/investor-relations" className="black-link-Genesis">
                Investor Relations
              </a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a href="/contact" className="black-link-Genesis">
                Contact
              </a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title font-weight-bold">
              <a
                href="https://www.genesiscareers.jobs/"
                className="black-link-Genesis"
              >
                Career
              </a>
            </h6>
          </MDBCol>
        </MDBRow>
        <hr className="black-text-Genesis" style={{ margin: '0 15%' }} />
        <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4 black-text-Genesis">
          <MDBCol lg="8" md="12" className="mt-5">
            <p style={{ lineHeight: '1.7rem' }} className="black-text-Genesis">
              Genesis Healthcare, Inc. is a holding company with subsidiaries
              that, on a combined basis, provide services to skilled nursing
              facilities and assisted/senior living communities. The Company
              also specializes in contract rehabilitation therapy, respiratory
              therapy, physician services, staffing services and accountable
              care.
            </p>
          </MDBCol>
          <MDBCol lg="4" md="12" className="mt-5" style={{ textAlign: 'left' }}>
            <p
              id="rightfooter"
              style={{ lineHeight: '1.7rem', padding: '0px 20px' }}
              className="black-text-Genesis"
            >
              <a href="/contact" className="black-link-Genesis">
                Connect and Contact Us
              </a>
              <br />
              101 East State Street,
              <br />
              Kennett Square, PA 19348
              <br />
            </p>
          </MDBCol>
        </MDBRow>
        <hr
          className="clearfix d-md-none rgba-white-light"
          style={{ margin: '10% 15% 5%' }}
        />
        <MDBRow className="pb-3">
          <MDBCol md="12">
            <div className="mb-5 flex-center">
              <a
                target="_blank"
                aria-label="linkedin - opens in a new window"
                href="https://www.linkedin.com/company/genesis-healthcare"
              >
                <span className="d-none">linkedin - opens in a new window</span>
                <MDBIcon
                  fab
                  icon="linkedin"
                  size="lg"
                  className="mx-2 mr-md-4 black-link-Genesis"
                />
              </a>
              <a
                target="_blank"
                aria-label="facebook - opens in a new window"
                href={'https://www.facebook.com/' + facebookURLsuffix}
              >
                <span className="d-none">facebook - opens in a new window</span>
                <MDBIcon
                  fab
                  icon="facebook"
                  size="lg"
                  className="mx-2 mr-md-4 black-link-Genesis"
                />
              </a>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <hr className="rgba-white-light" style={{ margin: '0' }} />
      <div
        className="red-bk-Genesis text-center py-3 white-text-Genesis"
        id={props.loc == '/' ? 'hpbottomfooter' : 'bottomfooter'}
      >
        <MDBContainer fluid className="black-text-Genesis-plain">
          <span className="black-text-Genesis-plain">
            &copy; {new Date().getFullYear()} Genesis HealthCare®
          </span>{' '}
          |
          <a
            href="/about-us/privacy"
            alt="Your Privacy"
            className="white-link-Genesis-plain"
          >
            {' '}
            Your Privacy{' '}
          </a>
          |
          <a
            href="/about-us/legal"
            alt="Legal"
            className="white-link-Genesis-plain"
          >
            {' '}
            Legal{' '}
          </a>
          |
          <a
            href="/about-us/compliance"
            alt="Compliance"
            className="white-link-Genesis-plain"
          >
            {' '}
            Compliance{' '}
          </a>
          |
          {shownj ? null : (
            <>
              <a
                href="/CenterFinancialStatement/Genesis_Financial_Reporting_Final_Issued_FY23_w_Audit_Opinion.pdf"
                alt="Compliance"
                className="white-link-Genesis-plain"
                target="_blank"
              >
                {' '}
                NJ Financial Disclosure{' '}
              </a>
              |
            </>
          )}
          <a href="/sitemap" alt="Sitemap" className="white-link-Genesis-plain">
            {' '}
            Sitemap{' '}
          </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer
